import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    catchErrorAndRetryAwait,
    checkQueryResponse,
    queryAwait,
} from '../api/api';
import {
    queryErrorHandler,
    querySuccessErrorHandler,
    sentryCaptureBasic,
} from '../errorHandlers';

const { responseLimit } = rangeHeadlessApi?.config;

const initialState = {
    selectedSpecialId: null,
    list: {
        data: null,
        status: 'idle',
        error: null,
    },
    isSpecials: false,
    retailerId: null,
};

export const fetchSpecialsList = createAsyncThunk(
    'specials/fetchSpecialsList',
    async (retailerId) => {
        const signal = AbortSignal.timeout(responseLimit);

        try {
            let response = await queryAwait(
                'GetSpecialsList',
                { retailerId },
                signal
            );

            response = await checkQueryResponse(response);

            return { response, retailerId };
        } catch (err) {
            let retryRes = await catchErrorAndRetryAwait(
                err,
                {
                    queryName: 'GetSpecialsList',
                    variables: { retailerId },
                },
                signal
            );

            retryRes = await checkQueryResponse(retryRes);

            return {
                response: retryRes,
                retailerId,
            };
        }
    }
);

export const specialsSlice = createSlice({
    name: 'specials',
    initialState,
    reducers: {
        setSelectedSpecialId: (state, action) => {
            state.selectedSpecialId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpecialsList.pending, (state) => {
                state.list.status = 'loading';
            })
            .addCase(fetchSpecialsList.fulfilled, (state, action) => {
                const { response, retailerId } = action.payload;

                if (response.hasOwnProperty('errors')) {
                    state.list.status = 'failed';
                    state.list.data = false;
                    state.list.error = response.errors;

                    querySuccessErrorHandler(response.errors, 'specialsSlice');
                } else {
                    state.list.status = 'succeeded';
                    state.list.data = response.data.specials;
                    state.retailerId = retailerId;
                    state.isSpecials = response.data.specials.length > 0;
                }
            })
            .addCase(fetchSpecialsList.rejected, (state, action) => {
                state.status = 'failed';
                state.data = false;
                state.error = action?.error;

                if (action && action.hasOwnProperty('error')) {
                    queryErrorHandler(action.error, 'specialsSlice');
                } else {
                    sentryCaptureBasic(
                        new Error(JSON.stringify({ state, action }))
                    );
                }
            });
    },
});

export const { setSelectedSpecialId } = specialsSlice.actions;

export default specialsSlice.reducer;
