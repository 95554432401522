import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getExistingCheckout } from '../util';
import {
    queryAwait,
    checkQueryResponse,
    catchErrorAndRetryAwait,
} from '../api/api';
import {
    queryErrorHandler,
    querySuccessErrorHandler,
    sentryCaptureBasic,
} from '../errorHandlers';
import { storageItemName } from '../config';

const { responseLimit } = rangeHeadlessApi?.config;

const initialState = {
    sidebarToggle: false,
    data: null,
    status: 'idle',
};

export const fetchOrCreateCart = createAsyncThunk(
    'cart/fetchOrCreateCart',
    async (retailerId) => {
        const signal = AbortSignal.timeout(responseLimit);
        let response;
        let type;

        const existingCheckout = getExistingCheckout();

        try {
            // if cart info exists & matches current location.
            if (
                existingCheckout &&
                existingCheckout.checkoutId &&
                existingCheckout.retailerId
            ) {
                const { checkoutId } = existingCheckout;
                const lsRetailerId = existingCheckout.retailerId;

                if (retailerId === lsRetailerId) {
                    type = 'checkout';
                    response = await queryAwait(
                        'FetchCartDetails',
                        {
                            retailerId,
                            checkoutId,
                        },
                        signal
                    );
                } else {
                    type = 'createCheckout';
                    window.localStorage.removeItem(storageItemName);

                    response = await queryAwait(
                        'CreateCheckout',
                        {
                            retailerId,
                            orderType: 'PICKUP',
                            pricingType: 'RECREATIONAL',
                        },
                        signal
                    );
                }
            } else {
                type = 'createCheckout';
                window.localStorage.removeItem(storageItemName);

                response = await queryAwait(
                    'CreateCheckout',
                    {
                        retailerId,
                        orderType: 'PICKUP',
                        pricingType: 'RECREATIONAL',
                    },
                    signal
                );
            }

            response = await checkQueryResponse(response);

            return { response, type, retailerId };
        } catch (err) {
            const queryName =
                type === 'checkout' ? 'FetchCartDetails' : 'CreateCheckout';

            const variables =
                type === 'checkout'
                    ? {
                          retailerId,
                          checkoutId: existingCheckout.checkoutId,
                      }
                    : {
                          retailerId,
                          orderType: 'PICKUP',
                          pricingType: 'RECREATIONAL',
                      };

            let retryRes = await catchErrorAndRetryAwait(
                err,
                { queryName, variables },
                signal
            );

            retryRes = await checkQueryResponse(retryRes);

            return {
                response: retryRes,
                type,
                retailerId,
            };
        }
    }
);

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.sidebarToggle = !state.sidebarToggle;
        },
        openSidebar: (state) => {
            state.sidebarToggle = true;
        },
        updateCart: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrCreateCart.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrCreateCart.fulfilled, (state, action) => {
                const { response, type, retailerId } = action.payload;

                if (response.hasOwnProperty('errors')) {
                    state.status = 'failed';
                    state.data = false;
                    state.error = response.errors;

                    querySuccessErrorHandler(response.errors, 'cartSlice');
                } else {
                    state.status = 'succeeded';
                    state.data = response.data[type];

                    if (type === 'createCheckout') {
                        window.localStorage.setItem(
                            storageItemName,
                            JSON.stringify({
                                checkoutId: response.data[type].id,
                                retailerId: retailerId,
                                createdAt: Date.now(),
                            })
                        );
                    }
                }
            })
            .addCase(fetchOrCreateCart.rejected, (state, action) => {
                state.status = 'failed';
                state.data = false;
                state.error = action?.error;

                if (action && action.hasOwnProperty('error')) {
                    queryErrorHandler(action.error, 'cartSlice');
                } else {
                    sentryCaptureBasic(
                        new Error(JSON.stringify({ state, action }))
                    );
                }
            });
    },
});

export const { toggleSidebar, openSidebar, updateCart } = cartSlice.actions;

export const selectSidebarToggle = (state) => state.cart.sidebarToggle;
export const selectCartData = (state) => state.cart.data;

export default cartSlice.reducer;
