import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const cacheSlice = createSlice({
    name: 'cache',
    initialState,
    reducers: {
        addEndpointToCache: (state, action) => {
            const { endpoint, data } = action.payload;
            if (!state[endpoint]) {
                state[endpoint] = data;
            }
        },
    },
});

export const { addEndpointToCache } = cacheSlice.actions;

export default cacheSlice.reducer;
