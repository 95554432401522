import {
    createPortal,
    useEffect,
    lazy,
    Suspense,
    useState,
} from '@wordpress/element';
import Shortcode from './Shortcode';
import LoadingSpinner from './components/LoadingSpinner';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchRetailerData,
    selectRetailerId,
    setRetailerId,
} from './store/retailerSlice';
import { fetchOrCreateCart } from './store/cartSlice';
import { getRetailerId } from './util';
import { useParams, useNavigate } from 'react-router-dom';
import { addData } from './store/configSlice';
import { ErrorBoundary } from '@sentry/react';
import ErrorComponent from './ErrorComponent';
import { queryFetch } from './api/api';
import { querySuccessErrorHandler } from './errorHandlers';
import useQueryError from './hooks/useQueryError';

const CartSidebar = lazy(() => import('./components/Cart/CartSidebar'));
const LocationModal = lazy(() =>
    import('./components/LocationSelect/LocationModal')
);

const App = ({ shortcodeData, globalConfig }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const retailerId = useSelector(selectRetailerId);
    const retailerDataStatus = useSelector((state) => state.retailer.status);
    const cartDataStatus = useSelector((state) => state.cart.status);

    const setThrowError = useQueryError();

    const [retailers, setRetailers] = useState(null);

    // Retailer is only explicitly set via 'headlessproducts' shortcode.
    const retailerShortcode = Object.values(shortcodeData).find(
        (data) => data.shortcode === 'headlessproducts'
    )?.retailerShortcode;

    // Only render the cart sidebar for these shortcodes.
    const renderCartSidebar = Object.values(shortcodeData).find(
        (data) =>
            data.shortcode === 'singleproduct' ||
            data.shortcode === 'headlessproducts' ||
            data.shortcode === 'headlessmenu'
    );

    /**
     * Dispatch Global Config and get all Retailers data.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(addData({ key: 'globalConfig', data: globalConfig }));

        queryFetch(
            'RetailersQuery',
            {},
            function (res) {
                if (res.hasOwnProperty('errors')) {
                    querySuccessErrorHandler(res.errors, 'App');
                } else {
                    const retailersData = res.data.retailers;

                    if (retailersData) {
                        const retailerIds = retailersData.map(
                            (retailer) => retailer.id
                        );

                        const filteredRetailers = Object.entries(
                            globalConfig?.allRetailersConfig?.retailers
                        )
                            .filter(([key, _]) => retailerIds.includes(key))
                            .map(([key, value]) => {
                                const niceName =
                                    value.niceName || value.retailerName;
                                const menuSlug = value.menuSlug || '';
                                const address =
                                    retailersData.find(
                                        (retailer) => retailer.id === key
                                    )?.address || '';

                                return {
                                    ...value,
                                    niceName,
                                    menuSlug,
                                    address,
                                };
                            });

                        setRetailers(filteredRetailers);
                    }
                }
            },
            signal,
            setThrowError
        );

        return () => controller.abort();
    }, []);

    /**
     * Once retailers data is loaded, set retailer ID if only one retailer, or check for retailer ID based off of shortcode atts, location, and/or config settings.
     */
    useEffect(() => {
        let isSingleRetailer =
            Array.isArray(retailers) && retailers.length === 1;

        let rId = isSingleRetailer
            ? retailers[0].id
            : getRetailerId(
                  retailerShortcode,
                  params,
                  globalConfig.allRetailersConfig
              );

        dispatch(setRetailerId(rId));

        if (
            isSingleRetailer &&
            params?.viewName === 'shop' &&
            typeof params.categoryName === 'undefined'
        ) {
            navigate(`/shop/${retailers[0].menuSlug}/`);
        }
    }, [retailers]);

    /**
     * Fetch and set retailer and cart data once Retailer ID is set or updated.
     */
    useEffect(() => {
        if (retailerId) {
            if (retailerDataStatus === 'idle') {
                dispatch(fetchRetailerData(retailerId));
            }
            if (cartDataStatus === 'idle') {
                dispatch(fetchOrCreateCart(retailerId));
            }
        }
    }, [retailerId, retailerDataStatus, cartDataStatus, dispatch]);

    /**
     * If client-side routing location slug changes, update retailer id.
     */
    useEffect(() => {
        if (
            Array.isArray(retailers) &&
            retailers.length > 1 &&
            params?.locationSlug
        ) {
            // Retailer is only explicitly set via 'headlessproducts' shortcode.
            const retailerShortcode = Object.values(shortcodeData).find(
                (data) => data.shortcode === 'headlessproducts'
            )?.retailerShortcode;

            let newRetailerId = getRetailerId(
                retailerShortcode,
                params,
                globalConfig.allRetailersConfig
            );

            if (newRetailerId !== false && retailerId !== newRetailerId) {
                dispatch(setRetailerId(newRetailerId));
                dispatch(fetchRetailerData(newRetailerId));
                dispatch(fetchOrCreateCart(newRetailerId));
            }
        }
    }, [params]);

    // if there's retailers data but no retailer ID set, it means a location has to be chosen.
    if (retailers && !retailerId) {
        return createPortal(
            <ErrorBoundary
                showDialog
                fallback={(props) => <ErrorComponent {...props} />}
            >
                <Suspense fallback={<LoadingSpinner />}>
                    <LocationModal retailers={retailers} />
                </Suspense>
            </ErrorBoundary>,
            document.body
        );
        // if there's retailers data and a retailer ID set, we're good to load the main app.
    } else if (retailers && retailerId) {
        return (
            <>
                {Object.entries(shortcodeData).map(([rootId, dataset], i) => {
                    let key = rootId.split('-').pop();
                    let root = document.getElementById(rootId);

                    const backendLoadingSpinner = document.getElementById(
                        `loading-${key}`
                    );
                    if (backendLoadingSpinner) {
                        backendLoadingSpinner.remove();
                    }

                    if (i === 0) {
                        return (
                            <Shortcode
                                key={i}
                                rootId={rootId}
                                shortcodeData={dataset}
                            />
                        );
                    } else {
                        return createPortal(
                            <Shortcode
                                key={i}
                                rootId={rootId}
                                shortcodeData={dataset}
                            />,
                            root
                        );
                    }
                })}
                {renderCartSidebar &&
                    createPortal(
                        <ErrorBoundary
                            showDialog
                            fallback={<ErrorBoundary showDialog />}
                        >
                            <Suspense fallback={<LoadingSpinner />}>
                                <CartSidebar />
                            </Suspense>
                        </ErrorBoundary>,
                        document.body
                    )}
            </>
        );
    } else {
        <LoadingSpinner />;
    }
};

export default App;
