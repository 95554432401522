import App from './App';
import {
    createRoot,
    render,
    lazy,
    Suspense,
    useEffect,
} from '@wordpress/element';
import store from './store/store';
import { Provider } from 'react-redux';
import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
} from 'react-router-dom';
import omit from 'lodash.omit';
import LoadingSpinner from './components/LoadingSpinner';
import './style/main.scss';
import * as Sentry from '@sentry/react';
// import { HttpClient } from '@sentry/integrations';
// import { Feedback } from '@sentry/browser';

const apiEndpoint = rangeHeadlessApi?.apiEndpoint;

const OrderConfirmation = lazy(() => import('./views/OrderConfirmation'));

/**
 * Shortcode data.
 */
let shortcodeData = {};

/**
 * Global config.
 */
let globalConfig = {};

const roots = document.querySelectorAll('[id^="range-headless-root-"]');
roots.forEach((root) => {
    if (root.dataset.rangeheadlessGlobal === 'true') {
        const data = JSON.parse(root.dataset.rangeheadless);
        globalConfig = data.globalConfig;
        shortcodeData[root.id] = omit(data, 'globalConfig');
    } else {
        shortcodeData[root.id] = JSON.parse(root.dataset.rangeheadless);
    }
});

const router = createBrowserRouter([
    {
        path: '/:viewName?/:locationSlug?/:categoryType?/:categoryName?',
        element: (
            <App shortcodeData={shortcodeData} globalConfig={globalConfig} />
        ),
    },
]);

/**
 * Sentry logging
 */
// if (process.env.NODE_ENV !== 'development') {
Sentry.init({
    environment:
        process.env.NODE_ENV === 'development' ? 'development' : 'production',
    dsn:
        process.env.NODE_ENV === 'development'
            ? // range-headless project.
              'https://fc2789e2a283f64b287ded0663f7f6ee@o4506138684162048.ingest.sentry.io/4506138685931520'
            : // pleasantrees-project.
              'https://a8a7f8dd0dde0042774e7a5cd5823e10@o4506138684162048.ingest.sentry.io/4506139211661312',

    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                createBrowserRouter,
                router,
                RouterProvider,
                useEffect,
                useLocation
            ),
        }),

        /**
         * Stop all console.logs coming from instument.js.
         */
        new Sentry.Integrations.Breadcrumbs({
            console: false,
        }),

        // new Feedback({
        //     // Additional SDK configuration goes in here, for example:
        //     colorScheme: 'light',
		// 	showBranding: false,
        // }),

        // trace 5xx errors.
        // new HttpClient({ failedRequestStatusCodes: [400, 599] }),
    ],
    // related to HttpClient config.
    // sendDefaultPii: true,

    tracePropagationTargets: [
        'localhost',
        /.+\/wp-json\/.+/,
        /^https:\/\/range-headless\.test.*/,
        apiEndpoint,
    ],

    // capture redux states.
    normalizeDepth: 10,
});
// }

/**
 * Check if Order Confirmation page.
 */
const orderConfirm = document.getElementById('range-headless-order-confirm');

/**
 * React 18+
 */
if (createRoot) {
    if (orderConfirm) {
        createRoot(document.body).render(
            <React.StrictMode>
                <Suspense fallback={<LoadingSpinner />}>
                    <OrderConfirmation
                        globalConfig={
                            JSON.parse(orderConfirm.dataset.rangeheadless)
                                .globalConfig
                        }
                    />
                </Suspense>
            </React.StrictMode>
        );
    } else {
        createRoot(roots[0]).render(
            <React.StrictMode>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </React.StrictMode>
        );
    }

    /**
     * Pre-React 18
     */
} else {
    if (orderConfirm) {
        createRoot(document.body).render(
            <React.StrictMode>
                <Suspense fallback={<LoadingSpinner />}>
                    <OrderConfirmation globalConfig={globalConfig} />
                </Suspense>
            </React.StrictMode>
        );
    } else {
        render(
            <React.StrictMode>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </React.StrictMode>,
            roots[0]
        );
    }
}
