import { print } from 'graphql';
import { checkStaleSession } from '../util';
import { GQL_Timeout_Error, queryErrorHandler } from '../errorHandlers';

import MenuQuery from './queries/MenuQuery.gql';
import ProductQuery from './queries/ProductQuery.gql';
import RetailerQuery from './queries/RetailerQuery.gql';
import RetailersQuery from './queries/RetailersQuery.gql';
import UpdateCheckout from './queries/UpdateCheckout.gql';
import CreateCheckout from './queries/CreateCheckout.gql';
import GetSpecialsList from './queries/GetSpecialsList.gql';
import MenuByStaffPicks from './queries/MenuByStaffPicks.gql';
import FetchCartDetails from './queries/FetchCartDetails.gql';
import AddItemToCheckout from './queries/AddItemToCheckout.gql';
import UpdateItemQuantity from './queries/UpdateItemQuantity.gql';
import OrdersByOrderNumber from './queries/OrdersByOrderNumber.gql';
import MenuBySpecialsSection from './queries/MenuBySpecialsSection.gql';
import RemoveItemFromCheckout from './queries/RemoveItemFromCheckout.gql';

const apiEndpoint = rangeHeadlessApi?.apiEndpoint;
const apiKey = rangeHeadlessApi?.apiKey;
const { responseLimit, retryTimeout, retryAmount } = rangeHeadlessApi?.config;

/**
 * Make a GQL request to the API endpoint.
 * Intended for use with async/await, returns response with no handling.
 *
 * @param {string} queryName String equivalent of GQL query name.
 * @param {object} variables Query variables.
 * @param {AbortSignal|null} signal Abort signal.
 *
 * @returns {Promise}
 */
export const queryAwait = async (queryName, variables, signal = null) => {
    if (checkStaleSession()) return;

    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${apiKey}`);
    myHeaders.append('Content-Type', 'application/json');

    if (queryName === 'debug') {
        return fetch('/wp-json/range_headless/v1/debug/500', {
            method: 'GET',
        });
    }

    const query = getQuery(queryName);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ query: print(query), variables }),
        redirect: 'follow',
    };

    if (signal) {
        requestOptions.signal = signal;
    }

    return fetch(apiEndpoint, requestOptions);
};

/**
 * Retry query on rejection.
 * Intended for async/await.
 *
 * @param {mixed} err Error response.
 * @param {object} args Query name and variables.
 * @param {AbortSignal|null} signal
 * @param {Number|null} retryCount
 */
export const catchErrorAndRetryAwait = async (
    err,
    args,
    signal,
    retryCount = null
) => {
    /**
     * Chrome & Safari have bug where TimeoutError throws an AbortError.
     * Which is why below the errors must be checked if the signal is present and contains a TimeoutError.
     * @see https://stackoverflow.com/questions/75969669/abortsignal-timeout-in-fetch-request-always-responds-with-aborterror-but-not-t
     */
    if (
        signal?.reason?.code === 20 ||
        (err?.code === 20 && signal?.reason?.code !== 23)
    ) {
        return; // AbortError
    } else if (signal?.reason?.code === 23 || err?.code === 23) {
        new GQL_Timeout_Error(
            signal.reason.message,
            [{ timeout: args.queryName }],
            {
                key: 'args',
                value: args,
            }
        );
    } else {
        queryErrorHandler(
            err,
            'catchErrorAndRetryAwait',
            { key: 'args', value: args },
            'Initial attempt'
        );
    }

    console.log(
        `${args.queryName} query failed, attemping again in ${retryTimeout}ms...`
    );

    const retry = await retryQueryAwait(args, retryCount);

    return retry;
};

/**
 * Execute a query after provided timeout length.
 *
 * @param {object} args Query name and variables.
 * @param {Number|null} retryCount
 */
export const retryQueryAwait = (args, retryCount) => {
    const count = retryCount ? retryCount - 1 : retryAmount;
    const shouldRetry = count !== 0;

    return new Promise((resolve) => {
        setTimeout(async () => {
            console.log(
                `Retrying query: ${args.queryName} - ${count} attempts remaining...`
            );
            resolve(
                await queryAwait(args.queryName, args.variables, false).catch(
                    async (err) => {
                        if (shouldRetry) {
                            await catchErrorAndRetryAwait(
                                err,
                                args,
                                null,
                                count
                            );
                        } else {
                            throw err;
                        }
                    }
                )
            );
        }, retryTimeout);
    });
};

/**
 * Make a GQL request to the API endpoint.
 * Intended for use with callback function.
 * Built-in handling to retry queries that return with server error.
 *
 * @param {string} queryName String equivalent of GQL query name.
 * @param {object} variables Query variables.
 * @param {AbortSignal|null} signal Abort signal.
 * @param {function|null} throwErrBoundary Set state function to hit component error boundary.
 * @param {bool} shouldRetry Whether query should retry after response limit or other error.
 * @param {Number|null} retryCount Number of times remaining to retry. `Null` will use config settings. `0` will not retry.
 *
 * @returns {void}
 */
export const queryFetch = (
    queryName,
    variables,
    responseFn,
    signal = null,
    throwErrBoundary = null,
    shouldRetry = true,
    retryCount = null
) => {
    if (checkStaleSession()) return;

    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${apiKey}`);
    myHeaders.append('Content-Type', 'application/json');

    let endpoint = apiEndpoint;
    const query = getQuery(queryName);

    let requestOptions = {};

    if (query) {
        requestOptions.method = 'POST';
        requestOptions.headers = myHeaders;
        requestOptions.body = JSON.stringify({
            query: print(query),
            variables,
        });
        requestOptions.redirect = 'follow';
    } else {
        requestOptions.method = 'GET';
    }

    if (signal) {
        // signal = AbortSignal.any([signal, AbortSignal.timeout(responseLimit)]);
        requestOptions.signal = AbortSignal.timeout(responseLimit);
    }

    if (queryName === 'OrdersByOrderNumber') {
        endpoint = '/wp-json/range_headless/v1/query/private';
    }

    fetch(endpoint, requestOptions)
        .then(checkQueryResponse)
        .then(responseFn)
        .catch((err) => {
            if (shouldRetry) {
                catchErrorAndRetryFetch(
                    err,
                    { queryName, variables },
                    responseFn,
                    signal,
                    throwErrBoundary,
                    retryCount ?? retryAmount
                );
            } else {
                queryErrorHandler(
                    err,
                    'catchErrorAndRetryFetch',
                    { key: 'args', value: variables },
                    'Final attempt failed.'
                );

                if (throwErrBoundary) {
                    throwErrBoundary(
                        `${queryName} exceeded query retry limit.`
                    );
                }
            }
        });
};

/**
 * Retry query on rejection.
 *
 * @param {mixed} err Error response.
 * @param {object} args Query name and variables.
 * @param {function} responseFn Response handler.
 * @param {AbortSignal|null} signal
 * @param {function|null} throwErrBoundary Set state function to hit component error boundary.
 * @param {Number|null} retryCount
 */
export const catchErrorAndRetryFetch = (
    err,
    args,
    responseFn,
    signal,
    throwErrBoundary,
    retryCount
) => {
    if (signal?.reason?.code === 20) {
        return; // AbortError
    } else if (signal?.reason?.code === 23) {
        new GQL_Timeout_Error(
            signal.reason.message,
            [{ timeout: args.queryName }],
            {
                key: 'args',
                value: args,
            }
        );
    } else {
        queryErrorHandler(
            signal?.reason?.code === 23 ? signal.reason : err, // check for TimeoutError
            'catchErrorAndRetryFetch',
            { key: 'args', value: args },
            'Initial attempt.'
        );
    }

    console.log(
        `${args.queryName} query failed, attemping again in ${retryTimeout}ms...`
    );

    retryQueryFetch(args, responseFn, throwErrBoundary, retryCount);
};

/**
 * Execute a query after provided timeout length.
 *
 * @param {object} args Query name and variables.
 * @param {function} responseFn Response handler.
 */
export const retryQueryFetch = (
    args,
    responseFn,
    throwErrBoundary,
    retryCount
) => {
    setTimeout(() => {
        console.log(
            `Retrying query: ${args.queryName} - ${retryCount} attempts remaining...`
        );

        const shouldRetry = retryCount !== 0;

        queryFetch(
            args.queryName,
            args.variables,
            responseFn,
            null,
            throwErrBoundary,
            shouldRetry,
            retryCount - 1
        );
    }, retryTimeout);
};

/**
 * Checks for 200 status and returns response, or rejects response.
 *
 * @param {Promise} res
 * @returns {Promise}
 */
export const checkQueryResponse = (res) => {
    if (!res.ok) {
        return Promise.reject(res);
    }

    return res.json();
};

/**
 * Get GQL query from string.
 * @param {string} queryName
 * @returns
 */
const getQuery = (queryName) => {
    switch (queryName) {
        case 'RetailerQuery':
            return RetailerQuery;
        case 'RetailersQuery':
            return RetailersQuery;
        case 'FetchCartDetails':
            return FetchCartDetails;
        case 'CreateCheckout':
            return CreateCheckout;
        case 'GetSpecialsList':
            return GetSpecialsList;
        case 'RemoveItemFromCheckout':
            return RemoveItemFromCheckout;
        case 'UpdateItemQuantity':
            return UpdateItemQuantity;
        case 'AddItemToCheckout':
            return AddItemToCheckout;
        case 'MenuByStaffPicks':
            return MenuByStaffPicks;
        case 'MenuBySpecialsSection':
            return MenuBySpecialsSection;
        case 'MenuQuery':
            return MenuQuery;
        case 'ProductQuery':
            return ProductQuery;
        case 'UpdateCheckout':
            return UpdateCheckout;
        case 'OrdersByOrderNumber':
            return OrdersByOrderNumber;
        default:
            return false;
    }
};

/**
 * Fuzzy search to get address suggestions.
 * @param {string} query URI encoded string to query.
 * @returns {Promise|error}
 */
export const getAddressSuggestions = async (query) => {
    const tomTomApiKey = process.env.TOMTOMAPI;

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
    };

    try {
        const response = await fetch(
            `https://api.tomtom.com/search/2/search/${query}.json?key=${tomTomApiKey}&countrySet=US,CA&idxSet=PAD,Addr`,
            requestOptions
        );
        return await response.json();
    } catch (error) {
        return console.log('error', error);
    }
};
