import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import retailerReducer from './retailerSlice';
import specialsReducer from './specialsSlice';
import cacheReducer from './cacheSlice';
import configReducer from './configSlice';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export default configureStore({
    reducer: {
        cart: cartReducer,
        retailer: retailerReducer,
        specials: specialsReducer,
        cache: cacheReducer,
        config: configReducer,
    },
    enhancers: [sentryReduxEnhancer],
    devTools: process.env.NODE_ENV === 'development',
});
