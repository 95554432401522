import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    globalConfig: null,
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        addData: (state, action) => {
            const { key, data } = action.payload;
            state[key] = data;
        },
    },
});

export const { addData } = configSlice.actions;
export const selectGlobalConfig = (state) => state.config.globalConfig;

export default configSlice.reducer;
