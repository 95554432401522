import { useState, useEffect } from '@wordpress/element';

/**
 * Allows a component error to be thrown from an API query, which can trigger an Error Boundary.
 */
export default function useQueryError() {
    const [throwError, setThrowError] = useState(false);

    useEffect(() => {
        if (throwError) {
            throw new Error(throwError);
        }
        return () => setThrowError(false);
    }, [throwError]);

    return setThrowError;
}
