import * as Sentry from '@sentry/react';
import { isPromise } from './util';

/**
 * Extend basic Error class with custom names for display in Sentry.
 */
export class Range_Headless_Error extends Error {
    /**
     * @param {string} message Main error message.
     * @param {object|null} tags Sentry tags.
     * @param {object|null} context Sentry context - object with 'key' and 'value' keys.
     * @param {string|null} transactionName Sentry transaction name.
     * @param {string} errName Custom error name.
     */
    constructor(
        message,
        tags = null,
        context = null,
        transactionName = null,
        errName
    ) {
        super(message);

        this.name = errName || 'Range_Headless_Error';

        this.message = message;
        this.tags = tags;
        this.transactionName = transactionName;
        this.context = context;

        this.consoleError();
        this.sentryCapture(this);
    }

    /**
     * Console error.
     */
    consoleError() {
        if (process.env.NODE_ENV === 'development') {
            console.error({
                message: this.message,
                tags: this.tags,
                context: this.context,
            });
        } else {
            console.error(this.message);
        }
    }

    /**
     * Sentry exception capturing.
     * @param {Error} err Error object with custom added properties for Sentry processing.
     */
    sentryCapture(err) {
        Sentry.captureException(err, (scope) => {
            if (err.transactionName) {
                scope.setTransactionName(err.transactionName);
            }

            if (err.tags) {
                if (Array.isArray(err.tags)) {
                    err.tags.forEach((tag) => scope.setTags(tag));
                } else {
                    scope.setTags(err.tags);
                }
            }

            if (Array.isArray(err.context)) {
                err.context.forEach((context) => {
                    scope.setContext(context.key, context.value);
                });
            } else if (err.context && err.context?.key && err.context?.value) {
                scope.setContext(err.context.key, err.context.value);
            }
            return scope;
        });
    }
}

/**
 * Error wrapper for WP REST queries.
 */
export class WP_REST_Error extends Range_Headless_Error {
    constructor(message, tags = null, context = null, transactionName = null) {
        super(message, tags, context, transactionName, 'WP_REST_Error');
    }
}

/**
 * Error wrapper for GQL queries.
 */
export class GQL_Query_Error extends Range_Headless_Error {
    constructor(message, tags = null, context = null, transactionName = null) {
        super(message, tags, context, transactionName, 'GQL_Query_Error');
    }
}

/**
 * Error wrapper for 200 status GQL responses with error properties.
 */
export class GQL_Response_Error extends Range_Headless_Error {
    constructor(message, tags = null, context = null, transactionName = null) {
        super(message, tags, context, transactionName, 'GQL_Response_Error');
    }
}

/**
 * Error wrapper for 200 status GQL responses with Internal Server Error.
 */
export class GQL_Response_Error__Internal extends Range_Headless_Error {
    constructor(message, tags = null, context = null, transactionName = null) {
        super(
            message,
            tags,
            context,
            transactionName,
            'GQL_Response_Error__Internal'
        );
    }
}

/**
 * Error wrapper for queries that surpass configured response timeout limit.
 */
export class GQL_Timeout_Error extends Range_Headless_Error {
    constructor(message, tags = null, context = null, transactionName = null) {
        super(message, tags, context, transactionName, 'GQL_Timeout_Error');
    }
}

/**
 * Pass exception directly to Sentry with no additional tags or info.
 * @param {Error} err Error object.
 */
export function sentryCaptureBasic(err) {
    console.error(err);
    Sentry.captureException(err);
}

/**
 * Checks GQL response for common errors that are expected and shouldn't be logged to Sentry.
 * @param {array} errors
 * @returns {bool}
 */
const hasCommonErrCode = (errors) => {
    const codes = [
        6, // "Sorry! You've reached the 2.5oz purchase limit for flower due to state regulations."
    ];

    return errors.some((err) => {
        if (err?.extensions && err.extensions.hasOwnProperty('code')) {
            return codes.includes(err.extensions.code);
        }
    });
};

/**
 * Checks GQL response for Internal Server Error, indicating something wrong on Dutchie's end.
 * @param {array} errors
 * @returns {bool}
 */
const hasInternalServerErr = (errors) => {
    return errors.some((err) => {
        if (err?.extensions && err.extensions.hasOwnProperty('code')) {
            return err.extensions.code === 'INTERNAL_SERVER_ERROR';
        }
    });
};

/**
 * Handle API fetch error.
 *
 * @param {mixed} err Error instance or 4xx/5xx status Response.
 * @param {string} fnName Name of function handler is called from.
 * @param {object} context Sentry context.
 * @param {string} addlInfo Optional additional info.
 */
export function queryErrorHandler(err, fnName, context = {}, addlInfo = '') {
    if (err instanceof GQL_Timeout_Error) {
        // no need to log, should already have logged in API timeout Promise.
        return;
    } else if (!err?.status && err.message) {
        sentryCaptureBasic(err);
    } else if (err instanceof Response) {
        let json = err.json();
        let tags = {
            url: err.url,
            statusCode: err.status,
            fnName,
        };

        if (addlInfo) {
            tags.addlInfo = addlInfo;
        }

        if (isPromise(json)) {
            json.then((msg) => {
                // assumes Dutchie error structure.
                new GQL_Query_Error(msg.errors[0].message, tags, context);
            });
        } else {
            new GQL_Query_Error(json, tags, context);
        }
    } else {
        console.error(err);
    }
}

/**
 * Handle a 200 Dutchie response that has an errors property.
 *
 * @param {array} errors Errors property of a 200 Dutchie response.
 * @param {string} componentName Component name that error is called from.
 */
export function querySuccessErrorHandler(errors, componentName) {
    if (hasCommonErrCode(errors)) {
        console.warn(errors);
    } else if (hasInternalServerErr(errors)) {
        new GQL_Response_Error__Internal(
            errors[0].message,
            { component: componentName },
            errors.map((err, i) => {
                return {
                    key: 'error' + i,
                    value: err,
                };
            })
        );
    } else {
        new GQL_Response_Error(
            errors[0].message,
            { component: componentName },
            errors.map((err, i) => {
                return {
                    key: 'error' + i,
                    value: err,
                };
            })
        );
    }
}

/**
 * Get array of error messages from Dutchie error response.
 * @param {array} errors Dutchie error response.
 * @returns {array} Array of message strings.
 */
export const getErrorMsgsToSet = (errors) => {
    return errors.map((error) => {
        if (error?.extensions?.errors) {
            return error.extensions.errors.map((extError) => extError.detail);
        } else {
            return error.message;
        }
    });
};
