import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    catchErrorAndRetryAwait,
    checkQueryResponse,
    queryAwait,
} from '../api/api';
import {
    queryErrorHandler,
    querySuccessErrorHandler,
    sentryCaptureBasic,
} from '../errorHandlers';

const { responseLimit } = rangeHeadlessApi?.config;

const initialState = {
    data: null,
    status: 'idle',
    error: null,
    id: null,
};

export const fetchRetailerData = createAsyncThunk(
    'retailer/fetchRetailerData',
    async (retailerId) => {
        const signal = AbortSignal.timeout(responseLimit);

        try {
            const response = await queryAwait(
                'RetailerQuery',
                { retailerId },
                signal
            );

            return await checkQueryResponse(response);
        } catch (err) {
            const retryRes = await catchErrorAndRetryAwait(
                err,
                {
                    queryName: 'RetailerQuery',
                    variables: { retailerId },
                },
                signal
            );

            return await checkQueryResponse(retryRes);
        }
    }
);

export const retailerSlice = createSlice({
    name: 'retailer',
    initialState,
    reducers: {
        setRetailerId: (state, action) => {
            state.id = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRetailerData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRetailerData.fulfilled, (state, action) => {
                if (action.payload.hasOwnProperty('errors')) {
                    state.status = 'failed';
                    state.data = false;
                    state.error = action.payload.errors;

                    querySuccessErrorHandler(
                        action.payload.errors,
                        'retailerSlice'
                    );
                } else {
                    state.status = 'succeeded';
                    state.data = action.payload.data.retailer;
                }
            })
            .addCase(fetchRetailerData.rejected, (state, action) => {
                state.status = 'failed';
                state.data = false;
                state.error = action?.error;

                if (action && action.hasOwnProperty('error')) {
                    queryErrorHandler(action.error, 'retailerSlice');
                } else {
                    sentryCaptureBasic(
                        new Error(JSON.stringify({ state, action }))
                    );
                }
            });
    },
});

export const { setRetailerId } = retailerSlice.actions;

export const selectRetailerData = (state) => state.retailer.data;
export const selectRetailerId = (state) => state.retailer.id;
export default retailerSlice.reducer;
