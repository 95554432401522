/**
 * Blaze configuration
 * @param {int} columns Columns att.
 * @returns {object}
 */
export const blazeConfig = (columns) => {
    return {
        all: {
            loop: true,
            enablePagination: false,
            slidesToScroll: 1,
            slidesToShow: 1,
        },
        '(min-width:767px)': {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
        '(min-width:1199px)': {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
        '(min-width:1399px)': {
            slidesToShow: columns,
            slidesToScroll: 1,
        },
    };
};

export const blazeConfigCategories = () => {
    return {
        all: {
            loop: true,
            enablePagination: false,
            slidesToScroll: 1,
            slidesToShow: 1,
        },
        '(min-width:767px)': {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
        '(min-width:1199px)': {
            slidesToShow: 4,
            slidesToScroll: 1,
        },
        '(min-width:1399px)': {
            slidesToShow: 5,
            slidesToScroll: 1,
        },
        '(min-width:1699px)': {
            slidesToShow: 6,
            slidesToScroll: 1,
        },
    };
};

export const blazeConfigSpecials = (columns) => {
    let sliderConfig = '';

    switch (columns) {
        case 1:
        case 3:
        case 4:
        case 5:
        case 6:
            sliderConfig = {
                all: {
                    loop: true,
                    enablePagination: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
                '(min-width:767px)': {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
                '(min-width:1199px)': {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
                '(min-width:1399px)': {
                    slidesToShow: columns,
                    slidesToScroll: 1,
                },
            };
            break;
        case 2:
            sliderConfig = {
                all: {
                    loop: true,
                    enablePagination: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
                '(min-width:767px)': {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            };
            break;
        case null:
        case undefined:
            sliderConfig = {
                all: {
                    loop: true,
                    enablePagination: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
                '(min-width:767px)': {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
                '(min-width:1199px)': {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            };
            break;
        default:
            sliderConfig = {
                all: {
                    loop: true,
                    enablePagination: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
                '(min-width:767px)': {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
                '(min-width:1199px)': {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            };
            break;
    }

    return sliderConfig;
};

export const blazeConfigMenuBanners = () => {
    return {
        all: {
            loop: true,
            enablePagination: false,
            slidesToScroll: 1,
            slidesToShow: 1,
            enableAutoplay: true,
            stopAutoplayOnInteraction: true,
            autoplayInterval: 5000,
        },
    };
};

export const menuFilter = {
    category: '',
    subcategory: '',
    strainType: '',
    brandId: '',
    effects: [],
    weights: [],
    search: '',
    potencyThc: { min: 0, max: 30, unit: 'PERCENTAGE' },
    potencyCbd: { min: 0, max: 10, unit: 'PERCENTAGE' },
    specials: false,
    staff_picks: false,
};

export const filterTypes = {
    category: 'category',
    subcategory: 'subcategory',
    brand: 'brandId',
    effects: 'effects',
    weights: 'weights',
    strain: 'strainType',
    potencyThc: 'potencyThc',
    potencyCbd: 'potencyCbd',
    custom: 'CUSTOM_SECTION',
    specials: 'specials',
    staff_picks: 'staff_picks',
    search: 'search',
};

export const defaultSort = { sortDir: 'DESC', sortKey: 'POPULAR' };

export const storageItemName =
    window.location.hostname.replace('.', '_').toUpperCase() +
    '_RANGE_HEADLESS';
