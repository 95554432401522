import { lazy, Suspense } from '@wordpress/element';
import { useParams } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner';
import { ErrorBoundary } from '@sentry/react';
import ErrorComponent from './ErrorComponent';

const Cart = lazy(() => import('./views/Cart'));
const Checkout = lazy(() => import('./views/Checkout'));
const ShopNav = lazy(() => import('./views/ShopNav'));
const ProductsView = lazy(() => import('./views/ProductsView'));
const Menu = lazy(() => import('./views/Menu'));
const MenuButton = lazy(() => import('./views/MenuButton'));
const SingleProduct = lazy(() => import('./views/SingleProduct'));

const Shortcode = ({ shortcodeData, rootId }) => {
    const params = useParams();
    const { shortcode } = shortcodeData;

    switch (shortcode) {
        case 'headlessmenu':
            return params?.categoryType === 'product' ? (
                <ErrorBoundary
                    showDialog
                    fallback={(props) => <ErrorComponent {...props} />}
                    beforeCapture={(scope) => {
                        scope.setTag('shortcode', shortcode);
                        scope.setTag('component', 'SingleProduct');
                    }}
                >
                    <Suspense fallback={<LoadingSpinner loadingType='list' />}>
                        <SingleProduct />
                    </Suspense>
                </ErrorBoundary>
            ) : (
                <ErrorBoundary
                    showDialog
                    fallback={(props) => <ErrorComponent {...props} />}
                    beforeCapture={(scope) => {
                        scope.setTag('shortcode', shortcode);
                        scope.setTag('component', 'Menu');
                    }}
                >
                    <Suspense fallback={<LoadingSpinner loadingType='list' />}>
                        <Menu rootId={rootId} />
                    </Suspense>
                </ErrorBoundary>
            );
        case 'headlessproducts':
            return (
                <ErrorBoundary
                    showDialog
                    fallback={(props) => <ErrorComponent {...props} />}
                    beforeCapture={(scope) => {
                        scope.setTag('shortcode', shortcode);
                        scope.setTag('component', 'ProductsView');
                    }}
                >
                    <Suspense fallback={<LoadingSpinner />}>
                        <ProductsView
                            shortcodeData={shortcodeData}
                            rootId={rootId}
                        />
                    </Suspense>
                </ErrorBoundary>
            );
        case 'headlesscart':
            return (
                <ErrorBoundary
                    showDialog
                    fallback={(props) => <ErrorComponent {...props} />}
                    beforeCapture={(scope) => {
                        scope.setTag('shortcode', shortcode);
                        scope.setTag('component', 'Cart');
                    }}
                >
                    <Suspense fallback={<LoadingSpinner />}>
                        <Cart />
                    </Suspense>
                </ErrorBoundary>
            );
        case 'headlesscheckout':
            return (
                <ErrorBoundary
                    showDialog
                    fallback={(props) => <ErrorComponent {...props} />}
                    beforeCapture={(scope) => {
                        scope.setTag('shortcode', shortcode);
                        scope.setTag('component', 'Checkout');
                    }}
                >
                    <Suspense fallback={<LoadingSpinner />}>
                        <Checkout />
                    </Suspense>
                </ErrorBoundary>
            );
        case 'shopnav':
            return (
                <ErrorBoundary
                    showDialog
                    fallback={(props) => <ErrorComponent {...props} />}
                    beforeCapture={(scope) => {
                        scope.setTag('shortcode', shortcode);
                        scope.setTag('component', 'ShopNav');
                    }}
                >
                    <Suspense fallback={<LoadingSpinner />}>
                        <ShopNav />
                    </Suspense>
                </ErrorBoundary>
            );
        case 'headlessmenubutton':
            return (
                <ErrorBoundary
                    showDialog
                    fallback={<ErrorComponent message='' />}
                    beforeCapture={(scope) => {
                        scope.setTag('shortcode', shortcode);
                        scope.setTag('component', 'MenuButton');
                    }}
                >
                    <Suspense fallback={<LoadingSpinner />}>
                        <MenuButton shortcodeData={shortcodeData} />
                    </Suspense>
                </ErrorBoundary>
            );
        default:
            return <h1>Shortcode error!</h1>;
    }
};

export default Shortcode;
